import React, { useState } from "react";

// components
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";

import packService from "~/utils/api/v1/packService";
import { numberWithPeriods } from "~/utils/numbers";

interface Props {
  id: string;
  packData: any;
  setError: any;
}

const PackData = (props: Props): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const deletePack = async (): Promise<void> => {
    setLoading(true);
    try {
      await packService.deletePack(props.id);
      navigate("/packs");
    } catch (err) {
      props.setError(err);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        open={modal}
        onClose={() => {
          setModal(false);
          document.getElementById("navbar").style.zIndex = "1999";
        }}
        style={{ height: "100%" }}
      >
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg shadow-lg">
          <div className="flex justify-center">
            <div className="p-12 rounded-lg bg-white border border-gray-200 flex flex-col">
              <h2 className="mb-8">Agregar administrador</h2>
              <Typography>Esta acción no se puede deshacer. ¿Está seguro que desea eliminar el pack?</Typography>
              <div className="flex items-center justify-center my-8">
                <button
                  className="text-[#039BE5] text-sm hover:opacity-70 hover:bg-[#039BE5]/10 uppercase border-[1.5px] border-[#039BE5] rounded-md px-12 py-2"
                  disabled={loading}
                  onClick={() => {
                    setModal(false);
                  }}
                >
                  Cancelar
                </button>
                <button
                  className="text-red-500 bg-white text-sm hover:opacity-70 uppercase border-[1.5px] border-red-500 rounded-md px-12 py-2"
                  disabled={loading}
                  onClick={deletePack}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="border p-4">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mx-4 mt-4">
            <p className="font-bold">Información del pack</p>
            <ButtonGroup variant="text">
              <Button
                color="secondary"
                onClick={() => {
                  setModal(true);
                }}
              >
                Eliminar pack
              </Button>

              <Button
                onClick={() => {
                  navigate(`/packs/${props.packData.id}/edit/`);
                }}
                color="primary"
              >
                Editar
              </Button>
            </ButtonGroup>
          </div>
          <hr />
          <div className="flex justify-start gap-40">
            <div className="grid grid-cols-12 grid-rows-2 p-8">
              <p className="font-bold">Nombre: </p>
              {props.packData.name}
              <p className="font-bold">Descripción: </p>
              {props.packData.description}
            </div>
            <div className="grid grid-cols-12 grid-rows-2 p-8">
              <p className="font-bold">Activo: </p>
              {props.packData.active ? "Si" : "No"}
              <p className="font-bold">Stackable: </p>
              {props.packData.stackable ? "Si" : "No"}
            </div>
            <div className="grid grid-cols-12 grid-rows-2 p-8">
              <p className="font-bold">Horas de ayuno: </p>
              {props.packData.fast_hours}
              <p className="font-bold">Tag: </p>
              {props.packData.tag}
            </div>
            {props.packData.external_reference && (
              <div className="grid grid-cols-12 grid-rows-2 p-8">
                <p className="font-bold">External reference: </p>
                {props.packData.external_reference}
              </div>
            )}
          </div>
          <div className="flex justify-start gap-20">
            <div className="grid grid-cols-9 grid-rows-1 gap-2 p-4">
              <>
                <p className="font-bold">Precio final: </p> {numberWithPeriods(props.packData.final_price)}
              </>
              <>
                <p className="font-bold">Precio original: </p> {numberWithPeriods(props.packData.strikethrough_price)}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackData;
